<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* #nav a.router-link-exact-active {
  color: #42b983;
} */

@font-face {
  font-family: 'OswaldMedium'; 
  src: url('assets/fonts/Oswald-Medium.ttf') format('truetype'); 
  font-style: normal; 
  font-weight: normal; 
} 

@font-face {
  font-family: 'OswaldRegular'; 
  src: url('assets/fonts/Oswald-Regular.ttf') format('truetype'); 
  font-style: normal; 
  font-weight: normal; 
} 

@font-face {
  font-family: 'OpenSansRegular'; 
  src: url('assets/fonts/OpenSans-Regular.ttf') format('truetype'); 
  font-style: normal; 
  font-weight: normal; 
} 
</style>
