<template>
  <div id='not-found'>
    404. Not found :(
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>